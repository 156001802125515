@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Custom colors
$primary: #fc5e03 !default;
$white: #fff !default;
$black: #000 !default;
$navbartext: #0212B3 !default;
$mainnavbackroudcolor: rgba(200, 200, 200, 0.5) !default;
$mainnavbackroudcolor_shrink: rgba(250, 250, 250, 1) !default;

// Custom Images
$header-image: "../img/engineering-blueprint-blur.jpg";
$contact-image: "../img/contact.png";

// Global CSS
@import "base/page.scss";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";
@import "components/client-scroll.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/services.scss";
@import "layout/portfolio.scss";
@import "layout/timeline.scss";
@import "layout/team.scss";
@import "layout/contact.scss";
@import "layout/footer.scss";
