// Styling for the masthead
header.masthead {
  text-align: center;
  color: #1b3e75;
  // background-color: $gray-900;
  background-image: url("#{$header-image}");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  // backdrop-filter: blur(50px);
  // background-color: $gray-900;
  // filter: blur(4px);

  .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;

    .intro-lead-in {
      font-size: 22px;
      font-style: italic;
      line-height: 22px;
      margin-bottom: 25px;
      @include serif-font;
      // background-color: $mainnavbackroudcolor;
    }

    .intro-heading {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 25px;
      @include heading-font;
      // background-color: $mainnavbackroudcolor;
    }
  }
}

@media (min-width: 768px) {
  header.masthead {
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;

      .intro-lead-in {
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
        margin-bottom: 25px;
        @include serif-font;
      }

      .intro-heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 50px;
        @include heading-font;
      }
    }
  }
}